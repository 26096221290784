/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React, { useState, useEffect } from 'react';
import * as Material from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import * as Components from './index';
import logo from '../assets/images/logo.jpg';
import * as HTTP from '../http/http';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	title: {
		//fontFamily: 'montserrat',
		fontSize: '20px'
	},
	image: {
		width: '30%',
		marginLeft: '70%'
	}
});

const Root = props => {
	const classes = useStyles();
	const [title, setTitle] = useState('Centro de ayuda');
	const [categories, setCategories] = useState([]);
	const [load, setLoad] = useState(true);
	const [selected, setSelected] = useState(false);
	const [itemSelect, setItemSelect] = useState({});
	const [taxonomy, setTaxonomy] = useState({});
	const [viewInfo, setViewInfo] = useState(false);
	const [preview, setPreview] = useState([]);
	const [thanks, setThanks] = useState(false);

	useEffect(() => {
		const getTaxonomy = async () => {
			setLoad(true);

			let taxonomy = await HTTP.getTaxonomy();
			if (taxonomy.status == 200) {
				setTaxonomy(taxonomy.data.data);
				if (taxonomy.data.data.hasOwnProperty('taxonomy')) {
					setCategories(taxonomy.data.data.taxonomy);
				}
			} else {
				//props.setError(true);
			}
			setLoad(false);
		};
		getTaxonomy();
	}, []);

	const selectCategory = (item, key) => {
		setSelected(true);
		setTitle(item.name);
		setItemSelect(item);
		let prev = preview;
		prev.push(key);
		if (item.hasOwnProperty('categories')) {
			setCategories(item.categories);
			setViewInfo(false);
		} else {
			setViewInfo(true);
		}
		setPreview(prev);
	};

	const back = () => {
		setViewInfo(false);
		let newCat = taxonomy['taxonomy'];
		let newTitle = 'Centro de ayuda';
		let array = preview;
		array.pop();
		for (let i = 0; i < array.length; i++) {
			let cats = newCat[array[i]]['categories'];
			newTitle = newCat[array[i]]['name'];
			newCat = cats;
		}

		if (array.length == 0) {
			setSelected(false);
		}
		setPreview(JSON.parse(JSON.stringify(array)));
		setTitle(newTitle);
		setCategories(newCat);
	};

	const changeThanks = value => {
		setThanks(value);
	};

	return (
		<div>
			{!load && !thanks && (
				<Material.Card>
					<Components.Header title={title} back={back} setTitle={setTitle} />
					<Material.Divider variant="fullWidth" />
					<Material.Divider variant="fullWidth" />
					<Material.CardMedia children={<div>{!selected && <img src={logo} className={classes.image} />}</div>} />
					<Components.Content taxonomy={taxonomy} />
				</Material.Card>
			)}
			{!load && thanks && <Components.Thanks />}
			{load && <Components.Loader />}
		</div>
	);
};

export default Root;
