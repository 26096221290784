import { createSlice } from '@reduxjs/toolkit';
/*const fetchUserById = createAsyncThunk(
	'users/fetchByIdStatus',
	async (userId, thunkAPI) => {
		const response = await userAPI.fetchById(userId)
		return response.data
	}
)*/
const article: IArticle = JSON.parse(sessionStorage.getItem('store'))?.article ?? {};

export interface IArticle {
	active: boolean;
	article: string;
	cdate: string;
	name: string;
	path: string;
	udate: string;
	uuid: string;
	uuidCategory: string;
	_id: string;
}

export const articleSlice = createSlice({
	name: 'article',
	initialState: article,
	reducers: {
		setArticle: (state, param) => param.payload
	}
	/*,extraReducers: {
		// Add reducers for additional action types here, and handle loading state as needed
		[fetchUserById.fulfilled]: (state, action) => {
			// Add user to the state array
			state.entities.push(action.payload)
		},
	},*/
});

// Action creators are generated for each case reducer function
export const { setArticle } = articleSlice.actions;

export const selectArticle = state => state.article;

export default articleSlice.reducer;
