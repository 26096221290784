/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React, { useState, useEffect } from 'react';
import * as Material from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { selectTickets, selectUuidTicket } from '../store/ticketSlice';

const useStyles = makeStyles({
	title: {
		color: '#3743AA',
		margin: 'auto',
		textAlign: 'center',
		fontSize: '14px'
	},
	image: {
		width: '80%',
		marginLeft: '10%',
		marginRight: '10%'
	},
	text: {
		fontSize: '14px',
		textAlign: 'center'
	}
});

const ListTicket = props => {
	const classes = useStyles();
	const [ticketsClose, setTicketClose] = useState([]);
	const [ticketsOpen, setTicketOpen] = useState([]);
	const tickets = useSelector(selectTickets);
	const dispatch = useDispatch();
	useEffect(() => {
		ReactGA.pageview('/tickets');
		let close = [];
		let open = [];
		for (let i = 0; i < tickets.length; i++) {
			if (tickets[i].status == 'open' || tickets[i].status == 'resolving') {
				open.push(tickets[i]);
			}

			if (tickets[i].status == 'resolve' || tickets[i].status == 'solved') {
				close.push(tickets[i]);
			}
		}

		setTicketOpen(JSON.parse(JSON.stringify(open)));
		setTicketClose(JSON.parse(JSON.stringify(close)));
	}, []);

	const goTicketDetail = uuid => {
		dispatch(selectUuidTicket(uuid));
		props.updateTicketUuid(uuid);
		props.changeNavPage('next', 'ticket');
	};
	return (
		<div>
			{ticketsOpen.length > 0 && <div className={'size-12 transactionTitle'}>Tickets en proceso</div>}
			{ticketsOpen.map((item, key) => {
				return (
					<div
						className={'size-12 cardIssue snow_background'}
						key={key}
						onClick={goTicketDetail.bind(this, item.ticket_uuid)}
					>
						<div className={'card'}>
							<h3>
								<p>{item.title}</p>
							</h3>
							<div className={'flex justify-between align-center'}>
								<span>{new Date(item['cdate']).toLocaleString('es-MX', { dateStyle: 'long' })} </span>
								{item.status == 'open' && <span className={'badge bolt_background white'}>En proceso</span>}
								{item.status == 'resolving' && <span className={'badge bolt_background white'}>En proceso</span>}
								{item.status == 'resolve' && <span className={'badge vivere_background white'}>Resuelto</span>}
							</div>
						</div>
					</div>
				);
			})}
			<br />
			<div className="snow_background"></div>
			{ticketsClose.length > 0 && <div className={'size-12 transactionTitle'}>Tickets resueltos</div>}
			{ticketsClose.map((item, key) => {
				return (
					<div
						className={'size-12 cardIssue snow_background'}
						key={key}
						onClick={goTicketDetail.bind(this, item.ticket_uuid)}
					>
						<div className={'card'}>
							<h3>
								<p>{item.title}</p>
							</h3>
							<div className={'flex justify-between align-center'}>
								<span>{new Date(item['cdate']).toLocaleString('es-MX', { dateStyle: 'long' })} </span>
								{item.status == 'open' && <span className={'badge bolt_background white'}>En proceso</span>}
								{item.status == 'resolving' && <span className={'badge bolt_background white'}>En proceso</span>}
								{item.status == 'resolve' && <span className={'badge vivere_background white'}>Resuelto</span>}
								{item.status == 'solved' && <span className={'badge vivere_background white'}>Resuelto</span>}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ListTicket;
