/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/albo-icons-fonts.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AppRoutes from './AppRoutes';
import { Provider } from 'react-redux';
import store from './store/store';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#3743AA'
		}
		//*secondary: green,
	},
	typography: {
		//fontFamily: 'Muli',
		fontSize: 12
	}
});

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<AppRoutes />
		</Provider>
	</ThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
