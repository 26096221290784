/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React, { useState, useEffect } from 'react';
import * as Material from '@material-ui/core';
import * as Components from './components';
import logo from './assets/images/UI-logo.svg';
import success from './assets/images/success.svg';
import noConnect from './assets/images/no-connect.svg';
import errorC from './assets/images/error-connect.svg';
import * as HTTP from './http/http';
import ReactGA from 'react-ga';
import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import { addUser, fetchToken, selectUser } from './store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import useTitle from './hooks/useTitle';
import { fetchTickets } from './store/ticketSlice';

const useStyles = makeStyles({
	title: {
		fontSize: '20px'
	},
	image: {
		width: '30%',
		marginLeft: '70%'
	}
});

const setIdAnalytics = () => {
	ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_UA, { testMode: process.env.NODE_ENV === 'test' });
};

// TODO: cambiar a rutas
const App = () => {
	useTitle('albo - Help Center');
	const classes = useStyles();
	const [maintenance, setMaintenance] = useState(false);
	const [icon, setIcon] = useState('close');
	const [title, setTitle] = useState('Centro de ayuda');
	const [load, setLoad] = useState(true);
	const [error, setError] = useState(false);
	const [queryParams, setQueryParams] = useState({});
	const [taxonomy, setTaxonomy] = useState({});
	const [nav, setNav] = useState([]);
	const [categories, setCategories] = useState([]);
	const [item, setItem] = useState({});
	const [page, setPage] = useState('');
	const [pageNav, setPageNav] = useState([]);
	const [finalPage, setFinalPage] = useState('');
	const [uuidTicket, setUuidTicket] = useState('');
	const [testUser, setTestUser] = useState([
		'14682717428893bce8036-e065-4a43-a9e3-22c78452d11b',
		'1583165262742d78535dc-5c99-43d5-be90-bcff7913e939',
		'1576712248102b696f006-c150-4c05-8d2f-a4efe43fef0f',
		'1569483060385b9aec366-908b-40f1-bd41-f67546d9ab21',
		'1540505530638a09a679f-a235-4bf1-a691-ef70f392603a',
		'15832709073077f50e6f8-6b18-443a-87ef-98ff57d93c55'
	]);

	const history = useHistory();
	const location = useLocation();
	let query = new URLSearchParams(location.search);
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const getTaxonomy = async ticket_uuid => {
		setLoad(true);

		let taxonomy = await HTTP.getTaxonomy();
		if (!taxonomy) {
			setError(true);
			setTitle('');
			setLoad(false);
		} else {
			if (taxonomy.status == 200) {
				setTaxonomy(taxonomy.data.data);
				if (taxonomy.data.data.hasOwnProperty('taxonomy')) {
					setCategories(taxonomy.data.data.taxonomy);
					if (ticket_uuid) {
						setUuidTicket(ticket_uuid);
						setIcon('back');
						setPage('updateTicket');
					} else {
						setPage('taxonomy');
					}
				}
			} else {
				setError(true);
				setTitle('');
				setIcon('');
				setPage('final');
			}
			setLoad(false);
		}
	};

	const setUser = async () => {
		let email = query.get('email');
		let name = query.get('name');
		let lastName = query.get('lastName');
		let secondLastName = query.get('secondLastName');
		let uuid = query.get('uuid');
		let tokenGcm = query.get('tokenGcm');
		let ticket_uuid = query.get('ticket_uuid');
		let dataUser;
		if (email && name && lastName && secondLastName && uuid && tokenGcm) {
			await dispatch(fetchToken({ uuid, tokenGcm, email }));
			dispatch(addUser({ email, name, lastName, secondLastName, uuid, tokenGcm, ticket_uuid }));
			history.replace({
				search: null
			});
			dataUser = { email, name, lastName, secondLastName, uuid, tokenGcm, ticket_uuid };
		} else if (user) {
			dataUser = { ...user };
		}

		if (!dataUser) {
			setError(true);
			setTitle('');
			setFinalPage('errorConnect');
			setPage('final');
		} else {
			setQueryParams({
				email: dataUser.email.replace(/ /gi, '+'),
				name: `${dataUser.name} ${dataUser.lastName} ${dataUser.secondLastName}`.trim(),
				uuid: dataUser.uuid,
				tokenGcm: dataUser.tokenGcm
			});
			ReactGA.event({
				category: 'User',
				action: 'Help Center user:' + dataUser.uuid
			});
			getTaxonomy(dataUser.ticket_uuid);
			// dispatch(fetchTaxonomy());
			dispatch(fetchTickets());
		}
	};

	useEffect(() => {
		setIdAnalytics();
		setUser();
	}, []);

	const changeNav = (type, key) => {
		let newNav = nav;
		if (type == 'next') {
			newNav.push(key);
		}

		if (type == 'back') {
			newNav.pop();
		}

		let cats = taxonomy['taxonomy'];
		let itemSelect = {};
		for (let i = 0; i < newNav.length; i++) {
			itemSelect = cats[newNav[i]];
			cats = cats[newNav[i]]['categories'];
		}

		if (newNav.length == 0) {
			setIcon('close');
		}

		if (newNav.length > 0) {
			setIcon('back');
		}

		setCategories(cats);
		setItem(itemSelect);

		updateNav(newNav);
	};

	const changeNavPage = (type, page) => {
		let newNav = pageNav;
		if (type == 'next') {
			newNav.push(page);
		}

		if (type == 'back') {
			newNav.pop();
		}

		let newPage = page;
		for (let i = 0; i < newNav.length; i++) {
			newPage = newNav[i];
		}

		if (newNav.length == 0) {
			newPage = 'taxonomy';
		}

		if (newNav.length == 0 && nav.length == 0) {
			setIcon('close');
		}

		if (newNav.length > 0 && nav.length == 0) {
			setIcon('back');
		}

		setPage(newPage);

		updatePageNav(newNav);
	};

	const updatePageNav = page => {
		setPageNav(page);
	};

	const updateError = flag => {
		setError(flag);
	};

	const updateLoad = flag => {
		setLoad(flag);
	};

	const updateIcon = type => {
		setIcon(type);
	};

	const updateNav = nav => {
		setNav(nav);
	};

	const updateItem = item => {
		setItem(item);
	};

	const updateTitle = title => {
		setTitle(title);
	};

	const updatePage = page => {
		setPage(page);
	};

	const updateFinalPage = page => {
		setFinalPage(page);
	};

	const updateCategories = categories => {
		setCategories(categories);
	};

	return (
		<div className={'App snow_background'}>
			{!load && !maintenance && (
				<div>
					<Components.Header
						title={title}
						icon={icon}
						changeNav={changeNav}
						changeNavPage={changeNavPage}
						nav={nav}
						pageNav={pageNav}
						page={page}
					/>
					<Material.CardMedia
						children={
							<div>
								{page == 'final' && finalPage == 'success' && <img src={success} />}
								{page == 'final' && finalPage == 'errorConnect' && <img src={errorC} />}
								{page == 'final' && finalPage == 'noConnect' && <img src={noConnect} />}
							</div>
						}
					/>
					<Components.Content
						taxonomy={taxonomy}
						queryParams={queryParams}
						error={error}
						updateError={updateError}
						load={load}
						updateLoad={updateLoad}
						updateIcon={updateIcon}
						updateTitle={updateTitle}
						updatePage={updatePage}
						nav={nav}
						categories={categories}
						changeNav={changeNav}
						item={item}
						page={page}
						changeNavPage={changeNavPage}
						finalPage={finalPage}
						updateFinalPage={updateFinalPage}
						updateNav={updateNav}
						updateCategories={updateCategories}
						uuidTicket={uuidTicket}
					/>
				</div>
			)}
			{!load && maintenance && testUser.indexOf(queryParams['uuid']) > -1 && (
				<div>
					<Components.Header
						title={title}
						icon={icon}
						changeNav={changeNav}
						changeNavPage={changeNavPage}
						nav={nav}
						pageNav={pageNav}
					/>
					<Material.CardMedia
						children={
							<div>
								{nav.length == 0 && page == 'taxonomy' && (
									<div className={'size-12 text-right white_background'}>
										<img src={logo} alt="" />
									</div>
								)}
								{page == 'final' && finalPage == 'success' && <img src={success} />}
								{page == 'final' && finalPage == 'errorConnect' && <img src={errorC} />}
								{page == 'final' && finalPage == 'noConnect' && <img src={noConnect} />}
							</div>
						}
					/>
					<Components.Content
						taxonomy={taxonomy}
						queryParams={queryParams}
						error={error}
						updateError={updateError}
						load={load}
						updateLoad={updateLoad}
						updateIcon={updateIcon}
						updateTitle={updateTitle}
						updatePage={updatePage}
						nav={nav}
						categories={categories}
						changeNav={changeNav}
						item={item}
						page={page}
						changeNavPage={changeNavPage}
						finalPage={finalPage}
						updateFinalPage={updateFinalPage}
						updateNav={updateNav}
						updateCategories={updateCategories}
						uuidTicket={uuidTicket}
					/>
				</div>
			)}
			{!load && maintenance && (
				<p>
					<em>Nuestro Centro de Ayuda&nbsp;est&aacute; en mantenimiento.&nbsp;</em>
					<em>Si tienes dudas escr&iacute;benos</em>
					<em>
						&nbsp;al correo&nbsp;
						<a href="&ldquo;mailto:soporte@albo.mx&rdquo;">soporte@albo.mx</a>&nbsp;
					</em>
				</p>
			)}
			{load && <Components.Loader />}
		</div>
	);
};

export default App;
