import { httpClient } from './httpClient';
const service = process.env.REACT_APP_API_URL_AUTH;

export interface IRequestBodyGetToken {
	uuid: string;
	email: string;
	tokenGcm: string;
}

export interface IRequestBodyRefreshToken {
	token: string;
}

interface IResponseAuth {
	token: string;
	refreshToken: string;
}

export const getToken = async (body: IRequestBodyGetToken): Promise<IResponseAuth> => {
	return await httpClient(`${service}/auth`, { body });
};

export const refreshToken = async (body: IRequestBodyRefreshToken): Promise<IResponseAuth> => {
	return await httpClient(`${service}/auth/refresh`, { body });
};
