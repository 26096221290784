import React, { useEffect } from 'react';
import styles from './ticketsuccess.module.css';
import success from './../../assets/images/success.svg';
import { useHistory } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';
import ReactGA from 'react-ga';

//TODO: mejorar componente
const TicketSuccessPage = () => {
	const history = useHistory();
	useTitle('Ticket Success');

	useEffect(() => {
		ReactGA.pageview('/ticket-success');
	}, []);

	return (
		<div>
			<img src={success} alt="albo ticket success" />
			<div>
				<h1 className={styles.title}>Tendrás una respuesta en menos de 15 minutos</h1>
				<br />
				<br />
				<br />
				<p className={styles.text}>Gracias por contactarnos, ¡tú eres lo más importante para nosotros!</p>
			</div>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<div className={'size-12 bigButton'}>
				<button onClick={() => history.push('/')}>Aceptar</button>
			</div>
		</div>
	);
};

export default TicketSuccessPage;
