import React, { useEffect, useState } from 'react';
import { HeaderPage, InputFile } from '../../components';
import styles from './ticketpage.module.css';
import ReactGA from 'react-ga';
import * as HTTP from '../../http/http';
import { useSelector } from 'react-redux';
import { IArticle, selectArticle } from '../../store/articleSlice';
import { IDataUser, selectUser } from '../../store/userSlice';
import Thumbnails from '../../components/Thumbnails/Thumbnails';
import { useHistory } from 'react-router-dom';
import * as Components from '../../components';
import useTitle from '../../hooks/useTitle';

//TODO: mejorar logica al generar ticket
const TicketPage = () => {
	useTitle('Ticket');
	const article: IArticle = useSelector(selectArticle);
	const user: IDataUser = useSelector(selectUser);
	const [title, setTitle] = useState('');
	const [titleError, setTitleError] = useState('');
	const [detail, setDetail] = useState('');
	const [detailError, setDetailError] = useState('');
	const [files, setFiles] = useState<string[]>([]);
	const [load, setLoad] = useState(false);
	const history = useHistory();
	const changeTitle = ({ target: { value } }) => setTitle(value);
	const changeDetail = ({ target: { value } }) => setDetail(value);
	const validateTitle = () => {
		if (title === '') {
			setTitleError('error');
		} else {
			setTitleError('');
		}
	};
	const validateDetail = () => {
		if (detail === '') {
			setDetailError('error');
		} else {
			setDetailError('');
		}
	};

	const handleSubmitTicket = async e => {
		e.preventDefault();

		const data = {
			customer: {
				uuid: user.uuid,
				email: user.email,
				name: user.name,
				tokenGcm: user.tokenGcm
			},
			ticket: {
				title,
				description: detail,
				supportReason: article.path.split('/')[1]
					? article.path.split('/')[1].toLowerCase().replace(/ /gi, '_').replace(/¿/gi, '').replace(/\?/gi, '')
					: article.path.split('/')[0].toLowerCase().replace(/ /gi, '_').replace(/¿/gi, '').replace(/\?/gi, ''),
				supportName: article.path.split('/')[1] ? article.path.split('/')[1] : article.path.split('/')[0],
				files
			},
			uuidCategory: article.uuid,
			nameCategory: article.name
		};

		if (title !== '' && detail !== '') {
			setLoad(true);
			let send = await HTTP.sendTicket({ data });
			ReactGA.event({
				category: 'CreateTicket',
				action: `User new ticket: ${user.uuid}`,
				label: article.name
			});
			if (send?.status === 200) {
				if (send.data.data.hasOwnProperty('exception')) {
					history.push('ticket-error');
				} else {
					history.push('ticket-success');
				}
			} else {
				history.push('ticket-error');
			}
		}
	};
	const onSelectFiles = (newFiles: string): void => {
		setFiles([...files, newFiles]);
	};
	const removeFile = index => {
		setFiles(files.filter((_, i) => i !== index));
	};

	useEffect(() => {
		ReactGA.pageview('/ticket-create');
	}, []);
	return (
		<>
			{!load && (
				<>
					<HeaderPage showLogo={false} goBack={true} />
					<p>
						<b>{article.name}</b>
					</p>
					<form onSubmit={handleSubmitTicket} className={styles.form}>
						<p>Necesitamos que llenes los siguientes campos para ayudarte a solucionar este problema.</p>
						<label htmlFor="title">
							<p>Título del problema</p>
						</label>
						<input
							maxLength={60}
							type="text"
							id="title"
							placeholder="Máximo 60 caracteres"
							value={title}
							className={titleError}
							onChange={changeTitle}
							onBlur={validateTitle}
						/>
						<label htmlFor="detail">
							<p>Detalla cuál es el problema</p>
						</label>
						<textarea
							id="detail"
							placeholder="Escribe aquí todos los detalles para poder ayudarte"
							value={detail}
							required
							onChange={changeDetail}
							onBlur={validateDetail}
							className={`size-12 ${detailError}`}
						/>
						<Thumbnails files={files} removeFile={removeFile} />
						{files.length < 2 && (
							<>
								<p>Agrega imágenes o capturas de pantalla si es necesario</p>
								<InputFile onSelectFile={onSelectFiles} />
							</>
						)}
						<div
							className={`size-12 bigButton ${!(title !== '' && detail !== '') ? 'disable' : ''}`}
							style={{ marginTop: '24px' }}
						>
							<button type="submit" disabled={!(title !== '' && detail !== '')}>
								Enviar
							</button>
						</div>
					</form>
				</>
			)}
			{load && <Components.Loader />}
		</>
	);
};

export default TicketPage;
