/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React from 'react';
import * as Material from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		}
	})
);

const Loader = () => {
	const classes = useStyles();
	return (
		<Material.Backdrop open={true} className={classes.backdrop}>
			<Material.CircularProgress />
		</Material.Backdrop>
	);
};

export default Loader;
