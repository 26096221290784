/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React, { useState, useEffect, Component } from 'react';
import * as Material from '@material-ui/core';
import * as Components from './index';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const Content = props => {
	const [ticketUuid, setTicketUuid] = useState('');

	const updateTicketUuid = uuid => {
		setTicketUuid(uuid);
	};

	return (
		<Material.CardContent style={{ minHeight: '650px' }}>
			{props.page == 'taxonomy' && (
				<Components.Taxonomy
					categories={props.categories}
					nav={props.nav}
					changeNav={props.changeNav}
					item={props.item}
					changeNavPage={props.changeNavPage}
					updateLoad={props.updateLoad}
					queryParams={props.queryParams}
				/>
			)}
			{props.page == 'article' && (
				<Components.Article item={props.item} queryParams={props.queryParams} changeNavPage={props.changeNavPage} />
			)}
			{props.page == 'ticketForm' && (
				<Components.TicketForm
					item={props.item}
					queryParams={props.queryParams}
					updateTitle={props.updateTitle}
					updateIcon={props.updateIcon}
					updatePage={props.updatePage}
					updateFinalPage={props.updateFinalPage}
				/>
			)}
			{props.page == 'final' && (
				<Components.Final
					finalPage={props.finalPage}
					updatePage={props.updatePage}
					updateNav={props.updateNav}
					taxonomy={props.taxonomy}
					updateCategories={props.updateCategories}
				/>
			)}
			{props.page == 'listTicket' && (
				<Components.ListTicket updateTicketUuid={updateTicketUuid} changeNavPage={props.changeNavPage} />
			)}
			{props.page == 'ticket' && <Components.Ticket ticketUuid={ticketUuid} queryParams={props.queryParams} />}
			{props.page == 'updateTicket' && (
				<Components.Ticket ticketUuid={props.uuidTicket} queryParams={props.queryParams} />
			)}
		</Material.CardContent>
	);
};

export default Content;
