import { httpClientAuth } from './httpClient';
const service = process.env.REACT_APP_API_URL_TICKETS;

const getTickets = async (): Promise<any> => {
	return await httpClientAuth(`${service}/tickets`);
};

const getTicket = async (ticket_uuid: string): Promise<any> => {
	return await httpClientAuth(`${service}/tickets/${ticket_uuid}`);
};

export { getTickets, getTicket };
