//TODO: resolver warnigs
/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React, { useEffect, useRef } from 'react';
import styles from './search.module.css';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import useInput from '../../hooks/useInput';
import cleanSearch from './../../assets/images/clean-search.svg';

function Search({ focus = false, showCancel = false, search = _ => {} }) {
	const inputRef: React.MutableRefObject<HTMLInputElement> = useRef();
	const history = useHistory();
	const [query, queryInput, setQuery] = useInput({
		type: 'text',
		placeholder: 'Buscar',
		ref: inputRef,
		className: styles.search,
		maxLength: 35
	});

	const transition = {
		duration: 0.5,
		ease: [0.43, 0.13, 0.23, 0.96]
	};

	let imageVariants = {};

	if (showCancel) {
		imageVariants = {
			exit: { width: '100%', transition },
			enter: {
				width: '84%',
				transition
			}
		};
	}

	useEffect(() => {
		if (focus) {
			inputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		search(query);
	}, [query]);

	return (
		<div className={styles.searchContainer}>
			<p>¿Cómo podemos ayudarte?</p>
			<div>
				<motion.div initial="exit" animate="enter" exit="exit" variants={imageVariants} className={styles.inputSearch}>
					<i className={styles.icon + ' ibon-search'} />
					{queryInput}
					{query.length >= 1 && (
						<img
							onClick={() => {
								setQuery('');
								inputRef.current.focus();
							}}
							src={cleanSearch}
							alt="clear"
							className={styles.clean}
						/>
					)}
				</motion.div>
				{showCancel && (
					<button onClick={() => history.goBack()} className={styles.cancel}>
						Cancelar
					</button>
				)}
			</div>
		</div>
	);
}

export default Search;
