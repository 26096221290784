import React, { useCallback, useEffect, useState } from 'react';
import { HeaderPage } from '../../components';
import { motion } from 'framer-motion';
import styles from './searchpage.module.css';
import useTitle from '../../hooks/useTitle';
import { debounce } from 'lodash';
import Search from '../../components/Search/Search';
import * as HTTP from '../../http/http';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../store/userSlice';
import { IArticle, setArticle } from '../../store/articleSlice';
import alboLoading from '../../assets/images/loadingFullScreen.gif';
import { useHistory, Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import errorSearch from './../../assets/images/error-search.svg';
import emptySearch from './../../assets/images/empty-search.svg';

const transition = {
	duration: 0.5,
	ease: [0.43, 0.13, 0.23, 0.96]
};

const imageVariants = {
	exit: { height: 0, overflow: 'hidden', transition },
	exitToArticle: {},
	enter: {
		height: '80vh',
		overflow: 'hidden',
		background: 'white',
		transition
	}
};

const Loading = () => (
	<div className={`center ${styles.loading}`}>
		<img src={alboLoading} alt="albo loading" />
	</div>
);

const ResultList = ({ articles, getArticle }) => (
	<ul className={styles.results}>
		{articles.map(article => {
			return (
				<li key={article.uuid} onClick={() => getArticle(article.uuidCategory)}>
					<p>{article.name}</p>
				</li>
			);
		})}
	</ul>
);

const NotFind = () => (
	<div className={styles.message}>
		<p>
			<b>Tu búsqueda no tuvo resultados</b>
		</p>
		<img src={emptySearch} alt="albo no hay resultados search" />
		<p>
			<b>
				Intenta con otras palabras o explora las <Link to="/">categorías</Link>.
			</b>
		</p>
	</div>
);

const ServerError = () => (
	<div className={styles.message}>
		<p>
			<b>Ups… no pudimos procesar tu solicitud</b>
		</p>
		<img src={errorSearch} alt="albo error search" />
		<p>
			<b>Intenta de nuevo en unos minutos.</b>
		</p>
	</div>
);

const SearchPage = () => {
	useTitle('Busqueda');
	const customer = useSelector(selectUser);
	const dispatch = useDispatch();
	const [loadingArticle, setLoadingArticle] = useState(false);
	const [exitToArticle, setExitToArticle] = useState(false);
	const history = useHistory();
	const [articles, setArticles] = useState<IArticle[]>([]);
	const [serverError, setServerError] = useState(false);
	const [searchStart, setSearchStart] = useState(false);
	const debounceQuery = useCallback(
		debounce(async query => {
			if (query.length >= 3) {
				try {
					//TODO: implementar thunk
					const res = await fetch(process.env.REACT_APP_API_URL_SEARCH + query);
					const response = await res.json();
					if (response.status === 500) {
						setServerError(true);
					} else {
						setArticles(response.data);
					}
				} catch (e) {
					console.error(e);
					setServerError(true);
				}
				setLoadingArticle(false);
			} else {
				if (serverError) {
					setServerError(false);
				}
			}
		}, 1000),
		[]
	);

	const handleSearch = query => {
		setServerError(false);
		if (query.length >= 3) {
			setLoadingArticle(true);
			setSearchStart(true);
		} else {
			setLoadingArticle(false);
			setSearchStart(false);
		}
		debounceQuery(query);
	};

	useEffect(() => {
		ReactGA.pageview('/search');
	}, []);

	const getArticle = async uuid => {
		setLoadingArticle(true);
		let art = await HTTP.getArticle(uuid, customer);
		if (art.status === 200) {
			setExitToArticle(() => true);
			dispatch(setArticle(art.data.data));
			history.push(`/article/${uuid}`);
		}
	};

	return (
		<div className={styles.container}>
			<HeaderPage />
			<Search focus={true} showCancel={true} search={handleSearch} />
			<motion.div initial="exit" animate="enter" exit={exitToArticle ? '' : 'exit'} variants={imageVariants}>
				{!loadingArticle && articles?.length > 0 && !serverError && (
					<ResultList getArticle={getArticle} articles={articles} />
				)}
				{loadingArticle && searchStart && <Loading />}
				{!loadingArticle && articles?.length === 0 && !serverError && searchStart && <NotFind />}
				{serverError && <ServerError />}
			</motion.div>
		</div>
	);
};

export default SearchPage;
