import React, { useEffect } from 'react';
import * as Material from '@material-ui/core';
import * as Components from './index';
import right from '../assets/icons/chevron-right.svg';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { selectTickets } from '../store/ticketSlice';

const Taxonomy = props => {
	// TODO: implementar loading
	const load = false;
	const tickets = useSelector(selectTickets);

	useEffect(() => {
		ReactGA.pageview('/');
	}, []);

	const selectCategory = (item, key) => {
		ReactGA.event({
			category: `CategorySelected`,
			action: `User ${props.queryParams.uuid}`,
			label: item.name
		});
		props.changeNav('next', key);
		if (!item.hasOwnProperty('categories')) {
			ReactGA.pageview(item.path);
			props.changeNavPage('next', 'article');
		}
	};

	return (
		<div>
			<Material.CssBaseline />
			<Material.CardContent style={{ width: '100%' }}>
				{load && <Components.Loader />}
				{!load && (
					<div>
						{
							<div>
								{props.item.name && (
									<div className={'size-12 settings'}>
										<b>{props.item.name ? props.item.name : ''}</b>
									</div>
								)}
								{props.categories &&
									props.categories.map((item, key) => {
										if (item.active) {
											return (
												<div key={key} className={'size-12 settings'} onClick={selectCategory.bind(this, item, key)}>
													<span>{item.name}</span>
													<img src={right} alt="" />
												</div>
											);
										}
									})}
							</div>
						}
					</div>
				)}
				{!load && tickets.length > 0 && props.nav && props.nav.length == 0 && !load && (
					<div className={'size-12 bigButton'} style={{ marginTop: '16px' }}>
						<button onClick={props.changeNavPage.bind(this, 'next', 'listTicket')}>Mis tickets</button>
					</div>
				)}
			</Material.CardContent>
		</div>
	);
};

export default Taxonomy;
