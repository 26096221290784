import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getComment } from '../http';
import { fetchRefreshToken } from './userSlice';

export const fetchComments = createAsyncThunk('comments/fetchComments', async (ticket_uuid: string, thunkAPI) => {
	let response = await getComment(ticket_uuid);
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getComment(ticket_uuid);
	}
	return response.data;
});

const commentsInit: IResponseComments = {
	comments: []
};
const comments: IResponseComments = JSON.parse(sessionStorage.getItem('store'))?.comments ?? commentsInit;

export interface IComment {
	agent?: string;
	cdate: string;
	comment_formatted: string;
	conversation: string;
	event: string;
	files: [];
	ticket_uuid: string;
	type_comment: string;
}

export interface IResponseComments {
	comments: IComment[];
}

export const commentsSlice = createSlice({
	name: 'comments',
	initialState: comments,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchComments.fulfilled, (state, action: PayloadAction<IResponseComments>) => {
			state.comments = action.payload.comments;
		});
	}
});

// Action creators are generated for each case reducer function
// export const { } = commentsSlice.actions;

export const selectComments = state => state.comments.comments;

export default commentsSlice.reducer;
