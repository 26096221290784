import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getToken, refreshToken } from '../http';
import { IRequestBodyGetToken, IRequestBodyRefreshToken } from '../http/authService';

export const fetchToken = createAsyncThunk('user/fetchToken', async (user: IRequestBodyGetToken, thunkAPI) => {
	const { uuid, tokenGcm, email } = user;
	let response;
	try {
		response = await getToken({ uuid, tokenGcm, email });
	} catch (e) {
		console.error(e);
	}
	return response;
});
export const fetchRefreshToken = createAsyncThunk(
	'user/fetchRefreshToken',
	async (req: IRequestBodyRefreshToken, thunkAPI) => {
		const { token } = req;
		let response;
		try {
			response = await refreshToken({ token });
		} catch (e) {
			console.error(e);
		}
		return response;
	}
);

const userInit: IUser = {
	data: {
		email: null,
		lastName: null,
		name: null,
		secondLastName: null,
		ticket_uuid: null,
		tokenGcm: null,
		uuid: null
	},
	auth: {
		token: null,
		refreshToken: null
	}
};

const user: IUser = JSON.parse(sessionStorage.getItem('store'))?.user ?? userInit;

export interface IDataUser {
	email: string;
	lastName: string;
	name: string;
	secondLastName: string;
	ticket_uuid: string;
	tokenGcm: string;
	uuid: string;
}

export interface IAuth {
	token: string;
	refreshToken: string;
}

export interface IUser {
	data: IDataUser;
	auth?: IAuth;
}

export const userSlice = createSlice({
	name: 'user',
	initialState: user,
	reducers: {
		addUser: (state, param: PayloadAction<IDataUser>) => {
			state.data = param.payload;
		},
		removeTicket: state => {
			state.data.ticket_uuid = null;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchToken.fulfilled, (state, action: PayloadAction<IAuth>) => {
			state.auth = action.payload;
		});
		builder.addCase(fetchRefreshToken.fulfilled, (state, action: PayloadAction<IAuth>) => {
			state.auth = action.payload;
		});
	}
});

// Action creators are generated for each case reducer function
export const { addUser, removeTicket } = userSlice.actions;
export const selectUser = state => state.user.data;
export const selectAuth = state => state.user.auth;
export default userSlice.reducer;
