//TODO: resolver warnigs
/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React, { useState, useEffect } from 'react';
import * as Material from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import * as Components from './index';
import * as HTTP from '../http/http';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
	title: {
		color: '#3743AA',
		margin: 'auto',
		textAlign: 'center'
	},
	image: {
		width: '80%',
		marginLeft: '10%',
		marginRight: '10%'
	}
});

const TicketForm = props => {
	const classes = useStyles();
	const [load, setLoad] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [classDisabled, setClassDisabled] = useState('disable');
	const [errorForm, setErrorForm] = useState({
		title: '',
		description: ''
	});
	const [formTicket, setFormTicket] = useState({
		data: {
			customer: {
				uuid: props.queryParams.uuid,
				email: props.queryParams.email,
				name: props.queryParams.name,
				tokenGcm: props.queryParams.tokenGcm
			},
			ticket: {
				description: '',
				title: '',
				supportReason: props.item.path.split('/')[1]
					? props.item.path.split('/')[1].toLowerCase().replace(/ /gi, '_').replace(/¿/gi, '').replace(/\?/gi, '')
					: props.item.path.split('/')[0].toLowerCase().replace(/ /gi, '_').replace(/¿/gi, '').replace(/\?/gi, ''),
				supportName: props.item.path.split('/')[1] ? props.item.path.split('/')[1] : props.item.path.split('/')[0],
				files: []
			},
			uuidCategory: props.item.uuid,
			nameCategory: props.item.name
		}
	});

	useEffect(() => {
		ReactGA.pageview('/ticket-form');
		if (formTicket.data.ticket.supportReason == 'envío') {
			formTicket.data.ticket.supportReason = 'envio';
		}
		ReactGA.event({
			category: 'IntentTicket',
			action: `User click ${props.queryParams.uuid}`,
			label: 'Necesito màs ayuda'
		});
	}, []);

	const changeValue = (field, e) => {
		let data = formTicket;
		let errors = errorForm;
		data.data.ticket[field] = e.target.value;

		if (!e.target.value.trim()) {
			errors[field] = 'error';
		} else {
			errors[field] = '';
		}

		if (data.data.ticket.title.trim() && data.data.ticket.description.trim()) {
			setClassDisabled('');
			setDisabled(false);
		} else {
			setClassDisabled('disable');
			setDisabled(true);
		}
		setErrorForm(JSON.parse(JSON.stringify(errors)));
		setFormTicket(JSON.parse(JSON.stringify(data)));
	};

	const sendTicket = async () => {
		ReactGA.event({
			category: 'CreateTicket',
			action: `User new ticket: ${props.queryParams.uuid}`,
			label: props.item.name
		});
		setLoad(true);
		let send = await HTTP.sendTicket(formTicket);
		if (send.status == 200) {
			if (send.data.data.hasOwnProperty('exception')) {
				props.updateTitle('');
				props.updateIcon('');
				props.updatePage('final');
				props.updateFinalPage('errorConnect');
			} else {
				props.updateTitle('');
				props.updateIcon('');
				props.updatePage('final');
				props.updateFinalPage('success');
			}
			//props.changeThanks(true);
		}
	};

	const openUpload = () => {
		document.getElementById('uploadFile').click();
	};

	const onFileLoad = async (e, file) => {
		let img = e.target.files[0];
		let reader = new FileReader();
		if (img) {
			//if(this.valid(img)){
			reader.readAsDataURL(img);
			reader.onload = await (function (binding) {
				return function () {
					let form = formTicket;
					let imagenes = form.data.ticket.files;
					imagenes.push(reader.result);
					setFormTicket(JSON.parse(JSON.stringify(form)));
				};
			})(this);
			/*}else{
                return alert("Formato incorrecto, la imagen debe estar en formato PNG ó JPEG");
            }*/
		}
	};

	const deleteImage = key => {
		let form = formTicket;
		let files = form.data.ticket.files;
		let newFiles = [];
		for (let i = 0; i < files.length; i++) {
			if (i != key) {
				newFiles.push(files[i]);
			}
		}

		form.data.ticket.files = newFiles;
		setFormTicket(JSON.parse(JSON.stringify(form)));
	};

	return (
		<div>
			{!load && (
				<div className={'snow_background'}>
					<p>
						<b>{props.item.name}</b>
					</p>
					<p>Necesitamos que llenes los siguientes campos para ayudarte a solucionar este problema.</p>
					<p>
						<label>Título del problema</label>
					</p>
					<input
						type="text"
						className={'size-12 ' + errorForm.title}
						placeholder="Máximo 60 caracteres"
						onChange={changeValue.bind(this, 'title')}
						value={formTicket.data.ticket.title}
						maxLength={60}
					/>
					{errorForm.title && (
						<div className={'size-12'}>
							<small className={'sunset'}>Campo obligatorio</small>
						</div>
					)}
					<p className={'size-12 snow_background'}>
						<label>Detalla cuál es el problema</label>
					</p>
					<textarea
						name="name"
						className={'size-12 ' + errorForm.description}
						placeholder="Escribe aquí todos los detalles para poder ayudarte"
						onChange={changeValue.bind(this, 'description')}
						value={formTicket.data.ticket.description}
					/>
					{errorForm.description && (
						<div className={'size-12'}>
							<small className={'sunset'}>Campo obligatorio</small>
						</div>
					)}
					{formTicket.data.ticket.files.length > 0 && (
						<Material.GridList cols={2.5}>
							{formTicket.data.ticket.files.map((item, key) => {
								return (
									<Material.GridListTile key={key}>
										<img src={item} />
										<Material.GridListTileBar
											title={'Archivo ' + (key + 1)}
											actionIcon={
												<Material.IconButton onClick={deleteImage.bind(this, key)}>
													<Icons.DeleteForeverRounded />
												</Material.IconButton>
											}
										/>
									</Material.GridListTile>
								);
							})}
						</Material.GridList>
					)}
					{formTicket.data.ticket.files.length < 2 && (
						<p className={'size-12 snow_background'}>
							<label>Agrega imágenes o capturas de pantalla si es necesario</label>
						</p>
					)}
					{formTicket.data.ticket.files.length < 2 && (
						<div className={'size-12 bigButton secundary '}>
							<button className={'flex justify-between align-center'} onClick={openUpload.bind(this)}>
								<span>Sube tu archivo</span>
								<span>+</span>
							</button>
						</div>
					)}
					{formTicket.data.ticket.files.length < 2 && (
						<input type="file" id={'uploadFile'} style={{ display: 'none' }} onChange={onFileLoad.bind(this)} />
					)}
					<div className={'size-12 bigButton ' + classDisabled} style={{ marginTop: '24px' }}>
						<button disabled={disabled} onClick={sendTicket.bind(this)}>
							Enviar
						</button>
					</div>
				</div>
			)}
			{load && <Components.Loader />}
		</div>
	);
};

export default TicketForm;
