import { configureStore } from '@reduxjs/toolkit';
import articleSlice from './articleSlice';
import userSlice from './userSlice';
import ticketsSlice from './ticketSlice';
import commentsSlice from './commetSlice';

const rootReducer = {
	article: articleSlice,
	tickets: ticketsSlice,
	user: userSlice,
	comments: commentsSlice
};

export default configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV !== 'production'
});
