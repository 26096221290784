import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTickets, getTicket } from '../http';
import { fetchRefreshToken } from './userSlice';

export const fetchTickets = createAsyncThunk('tickets/fetchTickets', async (_, thunkAPI) => {
	let response = await getTickets();
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getTickets();
	}
	return response.data;
});

export const fetchTicket = createAsyncThunk('tickets/fetchTicket', async (ticket_uuid: string, thunkAPI) => {
	let response = await getTicket(ticket_uuid);
	if (response.type === fetchRefreshToken.fulfilled.type) {
		response = await getTicket(ticket_uuid);
	}
	return response.data;
});

const ticketsInit: IResponseTickets = {
	tickets: [],
	meta: null,
	selectedTicket: {
		cdate: null,
		content: null,
		files: [],
		status: null,
		support_reason: null,
		ticket_uuid: null,
		title: null
	},
	selectedUuidTicket: null
};
const tickets: IResponseTickets = JSON.parse(sessionStorage.getItem('store'))?.tickets ?? ticketsInit;

export interface ITicket {
	cdate: string;
	content?: string;
	files?: [];
	status: string;
	support_reason?: string;
	ticket_uuid: string;
	title: string;
}
export interface IMeta {
	close: number;
	resolving: number;
	total: number;
}
export interface IResponseTickets {
	tickets: ITicket[];
	meta: IMeta;
	selectedTicket: ITicket;
	selectedUuidTicket: string;
}

export const ticketsSlice = createSlice({
	name: 'tickets',
	initialState: tickets,
	reducers: {
		selectUuidTicket: (state, param: PayloadAction<string>) => {
			state.selectedUuidTicket = param.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchTickets.fulfilled, (state, action: PayloadAction<IResponseTickets>) => {
			state.tickets = action.payload.tickets;
			state.meta = action.payload.meta;
		});
		builder.addCase(fetchTicket.fulfilled, (state, action: PayloadAction<ITicket>) => {
			state.selectedTicket = action.payload;
		});
	}
});

// Action creators are generated for each case reducer function
export const { selectUuidTicket } = ticketsSlice.actions;

export const selectTickets = state => state.tickets.tickets;
export const selectSelectedTicket = state => state.tickets.selectedTicket;
export const selectedUuidTicket = state => state.tickets.selectedUuidTicket;

export default ticketsSlice.reducer;
