//TODO: resolver warnigs
/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import * as Material from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { removeTicket, selectUser } from '../store/userSlice';
import { Search } from './index';
import { Link } from 'react-router-dom';
import logo from '../assets/images/UI-logo.svg';

const useStyles = makeStyles({
	title: {
		fontSize: '20px'
	}
});

const Header = props => {
	const user = useSelector(selectUser);
	const classes = useStyles();
	const dispatch = useDispatch();

	const back = () => {
		// TODO: remover esta logica por rutas
		if (user.ticket_uuid) {
			dispatch(removeTicket());
			const protocol = window.location.protocol;
			const host = window.location.host;
			const redirect = `${protocol}//${host}?email=${user.email}&name=${user.name}&lastName=${user.lastName}&secondLastName=${user.secondLastName}&uuid=${user.uuid}&tokenGcm=${user.tokenGcm}`;
			window.location.href = redirect;
		}
		if (props.pageNav.length == 0) {
			props.changeNav('back', null);
		} else if (props.pageNav.length == 1) {
			props.changeNav('back', null);
			props.changeNavPage('back', '');
		} else {
			props.changeNavPage('back', '');
		}
	};
	return (
		<div style={{ backgroundColor: 'white' }}>
			{props.icon == 'back' && (
				<Material.IconButton onClick={back.bind(this)}>
					<Icons.ChevronLeft />
				</Material.IconButton>
			)}
			<div
				style={{
					display: props.icon === 'back' ? 'inline-block' : 'flex',
					justifyContent: 'space-between',
					padding: '0 16px',
					borderBottom: props.icon === 'back' ? 'none' : '2px solid var(--color-neutral)'
				}}
			>
				<h1 className={`${classes.title}`} style={{ fontSize: 16, padding: 0 }}>
					{props.title}
				</h1>
				{props.nav.length == 0 && props.page == 'taxonomy' && <img src={logo} alt="" />}
			</div>
			{props.nav.length == 0 && props.page == 'taxonomy' && (
				<>
					<Link to={{ pathname: '/search' }} style={{ textDecoration: 'none' }}>
						<Search />
					</Link>
				</>
			)}
		</div>
	);
};

export default Header;
