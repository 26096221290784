import React from 'react';
import styles from './inputfile.module.css';

const InputFile = ({ onSelectFile }) => {
	const toBase64 = file =>
		new Promise<string | ArrayBuffer | null>((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

	const onFileLoad = async ({ target: { files } }) => {
		const fileBase64 = await toBase64(files[0]);
		onSelectFile(fileBase64);
	};

	return (
		<>
			<input style={{ display: 'none' }} type="file" id="uploadFile" onChange={onFileLoad} />
			<label htmlFor="uploadFile" className={styles.inputFile}>
				Sube tu archivo
			</label>
		</>
	);
};

export default InputFile;
