import { fetchRefreshToken } from '../store/userSlice';

let cacheStore;

interface ICustomRequest extends RequestInit {
	body?: any;
}

async function httpClientAuth(
	endpoint,
	{ body, ...customConfig }: ICustomRequest = { body: null, headers: null }
): Promise<any> {
	if (!cacheStore) {
		cacheStore = await import('../store/store');
	}
	const token = cacheStore.default.getState().user.auth.token;
	return httpClient(endpoint, { body, ...customConfig }, token);
}

function httpClient(
	endpoint,
	{ body, ...customConfig }: ICustomRequest = { body: null, headers: null },
	token = null
): Promise<any> {
	const customHeaders: HeadersInit = { 'content-type': 'application/json' };
	if (token) {
		customHeaders.Authorization = `Bearer ${token}`;
	}
	const config: RequestInit = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...customHeaders,
			...customConfig.headers
		}
	};
	if (body) {
		config.body = JSON.stringify(body);
	}

	return fetch(endpoint, config).then(async response => {
		if (response.status === 401) {
			if (!cacheStore) {
				cacheStore = await import('../store/store');
			}
			const refreshToken = cacheStore.default.getState().user.auth.refreshToken;
			return cacheStore.default.dispatch(fetchRefreshToken({ token: refreshToken }));
		}
		if (response.ok) {
			return await response.json();
		} else {
			const errorMessage = await response.text();
			throw JSON.parse(errorMessage);
		}
	});
}
export { httpClient, httpClientAuth };
