import axios from 'axios';

let baseUrl = process.env.REACT_APP_API_URL_LEGACY;
let apiBaseUrl = process.env.REACT_APP_API_URL;

export const getTaxonomy = async () => {
	let url = `${apiBaseUrl}/taxonomy`;
	let taxonomy = await axios({
		url
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			return error.response;
		});

	return taxonomy;
};

export const getArticle = async (uuidCategory, userInfo) => {
	let url = `${apiBaseUrl}/articles/${uuidCategory}`;
	let article = await axios({
		url
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			return error.response;
		});

	return article;
};

export const sendTicket = async data => {
	let url = `${baseUrl}/ticketcreate`;
	let ticket = await axios({
		url: url,
		method: 'POST',
		data: data
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			return error.response;
		});

	return ticket;
};

export const feedbackArticle = async (customerUuid, articleUuid, rate, comments) => {
	let url = `${apiBaseUrl}/articles/feedback`;
	let data = {
		customer_uuid: customerUuid,
		article_uuid: articleUuid,
		article_rate: rate
	};

	if (comments) {
		data['article_comments'] = comments;
	}
	let feedback = await axios({
		url,
		method: 'POST',
		data: {
			data
		}
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			return error.response;
		});

	return feedback;
};

export const getTicket = async body => {
	let url = `${apiBaseUrl}/tickets/${body.ticket_uuid}`;
	let ticket = await axios({
		url
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			return error.response;
		});

	return ticket;
};

export const sendComment = async body => {
	let url = `${baseUrl}/commentcreate`;
	let comment = await axios({
		url: url,
		//url: `http://localhost:8080/customer_service/service/CommentCreate@1.0`,
		method: 'POST',
		data: body
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			return error.response;
		});

	return comment;
};

export const getComments = async uuid => {
	let url = `${apiBaseUrl}/comments`;
	let list = await axios({
		url: url,
		method: 'POST',
		data: {
			data: {
				ticket_uuid: uuid
			}
		}
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			return error.response;
		});

	return list;
};

export const feedbackTicket = async data => {
	let url = `${baseUrl}/ticketfeedback`;

	let feedback = await axios({
		url: url,
		method: 'POST',
		data: data
	})
		.then(response => {
			return response;
		})
		.catch(error => {
			return error.response;
		});

	return feedback;
};
