//TODO: resolver warnigs
/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React, { useState, useEffect } from 'react';
import * as Material from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import * as HTTP from '../http/http';
import * as Components from './index';
import ReactHtmlParser from 'react-html-parser';
import imgDoc from '../assets/images/document.png';
import { makeStyles } from '@material-ui/core/styles';
import minLogo from '../assets/images/UI-logoItem.png';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicket, selectedUuidTicket, selectSelectedTicket } from '../store/ticketSlice';
import { fetchComments, selectComments } from '../store/commetSlice';

const useStyles = makeStyles({
	title: {
		//color: '#3743AA',
		//margin: 'auto',
		//textAlign: 'center',
		fontSize: '14px'
	},
	image: {
		width: '4%'
	},
	text: {
		fontSize: '14px'
		//textAlign: 'center'
	},
	content: {
		textAlign: 'center'
	},
	contentRate: {
		textAlign: 'center',
		marginBottom: '23px'
	},
	feedBackContent: {
		marginBottom: '12px'
	},
	contentButton: {
		textAlign: 'center',
		marginTop: '24px'
	},
	buttonSend: {
		marginTop: '24px'
	},
	rating: {
		margin: 'auto'
	},
	selected: {
		color: '#FDB201 !important'
	}
});

const Ticket = props => {
	const uuidTicket = useSelector(selectedUuidTicket);
	const classes = useStyles();
	const [load, setLoad] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [classDisabled, setClassDisabled] = useState('disable');

	const [showOptions, setShowOptions] = useState(false);
	const [valueRadio, setValueRadio] = useState('');
	const [disabledFeed, setDisabledFeed] = useState(true);
	const [end, setEnd] = useState(false);
	const [envio, setEnvio] = useState(false);
	const [val, setVal] = useState(0);
	const [bodyNew, setBodyNew] = useState({
		data: {
			customer: props.queryParams,
			ticket: {
				comment: '',
				uuid: uuidTicket,
				files: []
			}
		}
	});

	const [bodyFeed, setBodyFeed] = useState({
		data: {
			customer_uuid: props.queryParams.uuid,
			ticket_uuid: uuidTicket,
			ticket_rate: 0,
			feedback: ''
		}
	});

	const [nube, setNube] = useState({
		/*velocidad: {active: false, label: 'Velocidad', disabled: false}, 
        ortografia: {active: false, label: 'Ortografía', disabled: false}, 
        empatia: {active: false, label: 'Empatía', disabled: false}, 
        claridad: {active: false, label: 'Claridad', disabled: false}, 
        servicio: {active: false, label: 'Servicio', disabled: false}, 
        soluciones: {active: false, label: 'Soluciones', disabled: false}, 
        alternativas: {active: false, label: 'Alternativas', disabled: false}*/

		velocidad: { active: false, label: 'Velocidad de respuesta', disabled: false },
		resolucion: { active: false, label: 'No resolvieron mi problema', disabled: false },
		claridad: { active: false, label: 'Mi asesor no fue claro', disabled: false },
		ortografia: { active: false, label: 'Faltas de ortografía', disabled: false }
	});

	const claves = [
		/*'ortografia',
        'claridad',
        'empatia',
        'soluciones',
        'velocidad',
        'alternativas',
        'servicio',*/

		'ortografia',
		'claridad',
		'velocidad',
		'resolucion'
	];

	const dispatch = useDispatch();
	const selectedTicket = useSelector(selectSelectedTicket);
	const comments = useSelector(selectComments);
	const [ticket, setTicket] = useState(selectedTicket);
	const [listComments, setListComments] = useState(comments);

	useEffect(() => {
		setTicket(selectedTicket);
	}, [selectedTicket]);

	useEffect(() => {
		setListComments(comments);
	}, [comments]);

	useEffect(() => {
		ReactGA.pageview('/ticket');
		const getTicket = async () => {
			setLoad(true);
			await dispatch(fetchTicket(uuidTicket));
			await dispatch(fetchComments(uuidTicket));
			setLoad(false);
		};

		getTicket();
	}, []);

	const changeValue = e => {
		let value = e.target.value;
		let data = bodyNew;
		data.data.ticket.comment = value;
		setBodyNew(JSON.parse(JSON.stringify(data)));

		if (value) {
			setClassDisabled('');
			setDisabled(false);
		} else {
			if (data.data.ticket.files.length > 0) {
				setDisabled(false);
				setClassDisabled('');
			} else {
				setClassDisabled('disable');
				setDisabled(true);
			}
		}
	};

	const sendComment = async () => {
		setLoad(true);
		const add = await HTTP.sendComment(bodyNew);
		if (add.status == 200) {
			if (add.data.data.hasOwnProperty('uuid')) {
				let comments = listComments;
				let object = {
					type_comment: 'customer',
					cdate: new Date().toJSON(),
					conversation: bodyNew.data.ticket.comment,
					files: bodyNew.data.ticket.files,
					format: [[bodyNew.data.ticket.comment]]
				};
				comments.push(object);
				bodyNew.data.ticket.files = [];
				setListComments(JSON.parse(JSON.stringify(comments)));
			}
		}

		let data = bodyNew;
		data.data.ticket.comment = '';
		setBodyNew(JSON.parse(JSON.stringify(data)));
		setClassDisabled('disable');
		setDisabled(true);

		window.location.href = `${window.location.protocol}://${window.location.host}${window.location.search}&ticket_uuid=${uuidTicket}`;
		//setLoad(false);
	};

	const sendFeedback = async value => {
		if (value) {
			ReactGA.event({
				category: 'TicketRate',
				action: `User Ticket Rate: ${props.queryParams.uuid}`,
				label: `${value.toString()} ${uuidTicket}`
			});
			//bodyFeed.data.ticket_rate = true;
			setLoad(true);
			let feedback = await HTTP.feedbackTicket(bodyFeed);
			if (feedback.status == 200) {
				if (feedback.data.data.ticket_uuid && feedback.data.data.customer_uuid) {
					let t = ticket;
					t['ticket_rate'] = true;
					setTicket(JSON.parse(JSON.stringify(t)));
					setEnvio(true);
				}
			}
			setLoad(false);
		} else {
			ReactGA.event({
				category: 'TicketRate',
				action: `User Ticket Rate: ${props.queryParams.uuid}`,
				label: `${value.toString()} ${uuidTicket}`
			});
			setShowOptions(true);
		}
	};

	const selectValue = value => {
		setValueRadio(value);
		if (value) {
			setDisabledFeed(false);
		}
	};

	const sendValueFunc = async () => {
		setLoad(true);
		bodyFeed.data.ticket_rate = 0;
		bodyFeed.data.feedback = valueRadio;
		setLoad(true);
		ReactGA.event({
			category: 'TicketFeedback',
			action: `User Ticket Feedback: ${props.queryParams.uuid}`,
			label: `${valueRadio} ${uuidTicket}`
		});
		let feedback = await HTTP.feedbackTicket(bodyFeed);
		if (feedback.status == 200) {
			if (feedback.data.data.ticket_uuid && feedback.data.data.customer_uuid) {
				let t = ticket;
				t['ticket_rate'] = false;
				setTicket(JSON.parse(JSON.stringify(t)));
				setEnd(true);
			}
		}
		setLoad(false);
		//setSendValue(true);
	};

	const sendFeed = async () => {
		setLoad(true);
		let array = [];
		let keys = Object.keys(nube);
		for (let i = 0; i < keys.length; i++) {
			if (nube[keys[i]].active) {
				array.push(nube[keys[i]].label);
			}
		}

		bodyFeed.data.feedback = array.toString();

		ReactGA.event({
			category: 'TicketFeedbackStars',
			action: `User Ticket Feedback: ${props.queryParams.uuid}`,
			label: `stars: ${bodyFeed.data.ticket_rate}, nube: ${bodyFeed.data.feedback}`
		});

		let feedback = await HTTP.feedbackTicket(bodyFeed);
		if (feedback.status == 200) {
			if (feedback.data.data.ticket_uuid && feedback.data.data.customer_uuid) {
				let t = ticket;
				t['ticket_rate'] = bodyFeed.data.ticket_rate;
				setTicket(JSON.parse(JSON.stringify(t)));
				setEnd(true);
			}
		}
		setLoad(false);
	};

	const openUpload = () => {
		document.getElementById('uploadFile').click();
	};

	const onFileLoad = async (e, file) => {
		let img = e.target.files[0];
		let reader = new FileReader();
		if (img) {
			//if(this.valid(img)){
			reader.readAsDataURL(img);
			reader.onload = await (function (binding) {
				return function () {
					let form = bodyNew;
					let imagenes = form.data.ticket.files;
					imagenes.push(reader.result);
					setClassDisabled('');
					setDisabled(false);
					setBodyNew(JSON.parse(JSON.stringify(form)));
				};
			})(this);
			/*}else{
                return alert("Formato incorrecto, la imagen debe estar en formato PNG ó JPEG");
            }*/
		}
	};

	const deleteImage = key => {
		let form = bodyNew;
		let files = form.data.ticket.files;
		let newFiles = [];
		for (let i = 0; i < files.length; i++) {
			if (i != key) {
				newFiles.push(files[i]);
			}
		}

		if (newFiles.length > 0) {
			setClassDisabled('');
			setDisabled(false);
		} else {
			if (!form.data.ticket.comment) {
				setClassDisabled('disable');
				setDisabled(true);
			}
		}

		form.data.ticket.files = newFiles;
		setBodyNew(JSON.parse(JSON.stringify(form)));
	};

	const selectStar = (newRating, name) => {
		let body = bodyFeed;
		body.data.ticket_rate = newRating;
		for (let i = 1; i <= newRating; i++) {
			document.getElementById(`s${i}`).classList.add(classes.selected);
		}

		for (let i = newRating + 1; i < 6; i++) {
			document.getElementById(`s${i}`).classList.remove(classes.selected);
		}
		//setVal(newRating);
		if (newRating < 5) {
			setShowOptions(true);
			setDisabledFeed(true);
		} else {
			setShowOptions(false);
			setDisabledFeed(false);
			resetNube();
		}

		setBodyFeed(JSON.parse(JSON.stringify(body)));
	};

	const selectItem = key => {
		let n = nube;
		let keys = Object.keys(nube);
		let total = 0;
		if (n[key].disabled) {
			return;
		}
		n[key].active = !n[key].active;
		for (let i = 0; i < keys.length; i++) {
			total += n[keys[i]].active;
		}
		if (total >= 1) {
			setDisabledFeed(false);
		} else {
			setDisabledFeed(true);
		}
		if (total < 3) {
			for (let i = 0; i < keys.length; i++) {
				if (!n[keys[i]].active) {
					n[keys[i]].disabled = false;
				}
			}
		} else {
			for (let i = 0; i < keys.length; i++) {
				if (!n[keys[i]].active) {
					n[keys[i]].disabled = true;
				} else {
					n[keys[i]].disabled = false;
				}
			}
		}
		setNube(JSON.parse(JSON.stringify(n)));
	};

	const resetNube = () => {
		let keys = Object.keys(nube);
		let n = nube;
		for (let i = 0; i < keys.length; i++) {
			n[keys[i]].active = false;
			n[keys[i]].disabled = false;
		}
		setNube(JSON.parse(JSON.stringify(n)));
	};

	const download = async url => {
		/*let doc = await axios({
            method: 'GET',
            url: url,
        }).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });

        let d = new Blob([doc.data], {type: doc.headers['content-type']});

        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(d);
        let tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = `${url.split('name=')[url.split('name=').length-1]}`;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);*/
		//window.location.href = url;
		window.open(url, '_blank');
	};

	return (
		<div>
			{load && <Components.Loader />}
			{!load && (
				<div className={'snow_background'}>
					<h2 className={classes.title}>
						<b>{ticket['title']}</b>
					</h2>
					<Material.Divider />
					<div className={'size-12 chat'}>
						<div>{ticket['content']}</div>
						{ticket.hasOwnProperty('files') &&
							ticket['files'].length > 0 &&
							ticket['files'].map((item, key) => {
								return <img key={key} src={item} onClick={download.bind(this, item)} />;
							})}
						<div className={'neutral'}>{new Date(ticket['cdate']).toLocaleString('es-MX', { dateStyle: 'long' })}</div>
					</div>
					<Material.Divider />
					{!load &&
						listComments.map((item, key) => {
							let files = [];
							if (item['comment_formatted']) {
								let commentFormatted = item['comment_formatted'];
								files = commentFormatted.match(/https:\/\/.*/gi);
							}
							files = files.concat(item.files);

							return (
								<div key={key}>
									<div className={'size-12 chat'}>
										<div>{ReactHtmlParser(item['conversation'])}</div>
										{files.length > 0 &&
											files.map((item2, key2) => {
												if (
													item2.split('.')[item2.split('.').length - 1] == 'png' ||
													item2.split('.')[item2.split('.').length - 1] == 'jpg' ||
													item2.split('.')[item2.split('.').length - 1] == 'jpeg' ||
													item2.split('.')[item2.split('.').length - 1] == 'gif' ||
													item2.split('.')[item2.split('.').length - 1] == 'bmp'
												) {
													/*return <a href={item2} download={item2} rel="external nofollow noopener" target="_blank">
                                                    <img key={key2} src={item2}/>
                                                </a>*/
													return <img key={key2} src={item2} onClick={download.bind(this, item2)} />;
												} else {
													/*return <a href={item2} download>
                                                    <img key={key2} src={imgDoc}/>
                                                </a>*/
													//return //<a href={item2} download>
													return <img key={key2} src={imgDoc} onClick={download.bind(this, item2)} />;
													//</a>
												}
											})}
										{item['type_comment'] == 'customer' && (
											<div className={'neutral'}>
												{new Date(ticket['cdate']).toLocaleString('es-MX', { dateStyle: 'long' })}
											</div>
										)}
										{item['type_comment'] == 'agent' && (
											<div className={'flex justify-between align-center'}>
												<div className={'neutral'}>
													{new Date(ticket['cdate']).toLocaleString('es-MX', { dateStyle: 'long' })}
												</div>
												<img src={minLogo} alt="" className={classes.image} />
											</div>
										)}
									</div>
									<Material.Divider />
								</div>
							);
						})}
					{!load && bodyNew.data.ticket.files.length > 0 && (
						<Material.GridList cols={2.5}>
							{bodyNew.data.ticket.files.map((item, key) => {
								return (
									<Material.GridListTile key={key}>
										<img src={item} />
										<Material.GridListTileBar
											title={'Archivo ' + (key + 1)}
											actionIcon={
												<Material.IconButton onClick={deleteImage.bind(this, key)}>
													<Icons.DeleteForeverRounded />
												</Material.IconButton>
											}
										/>
									</Material.GridListTile>
								);
							})}
						</Material.GridList>
					)}
					{!load && ticket['status'] != 'solved' && !end && bodyNew.data.ticket.files.length < 2 && (
						<input type="file" id={'uploadFile'} style={{ display: 'none' }} onChange={onFileLoad.bind(this)} />
					)}
					{!load && ticket['status'] == 'solved' && !ticket.hasOwnProperty('ticket_rate') && !end && (
						<div className={'size-12 starRank'}>
							<div className={'startRankTitle bold'}>
								Queremos ofrecerte un servicio cada vez mejor. ¿Qué te pareció nuestra atención?
							</div>
							<div className={'startRanksIcons'}>
								<i className={'ibon-star'} id={'s5'} onClick={selectStar.bind(this, 5, 's5')}></i>
								<i className={'ibon-star'} id={'s4'} onClick={selectStar.bind(this, 4, 's4')}></i>
								<i className={'ibon-star'} id={'s3'} onClick={selectStar.bind(this, 3, 's3')}></i>
								<i className={'ibon-star'} id={'s2'} onClick={selectStar.bind(this, 2, 's2')}></i>
								<i className={'ibon-star'} id={'s1'} onClick={selectStar.bind(this, 1, 's1')}></i>
							</div>
							<Material.Divider style={{ marginTop: '20px' }} variant="fullWidth" />
						</div>
					)}
					{!load && ticket['status'] != 'solved' && !end && (
						<div>
							<p className={classes.text + ' size-12 snow_background'}>¿Quieres agregar algo más?</p>
							<input
								type="text"
								className={'size-12'}
								placeholder="Escribe aquí"
								value={bodyNew.data.ticket.comment}
								onChange={changeValue.bind(this)}
							/>
							{ticket['status'] != 'solved' && !end && bodyNew.data.ticket.files.length < 2 && (
								<p className={'size-12 snow_background'}>
									<label>Agrega imágenes o capturas de pantalla si es necesario</label>
								</p>
							)}
							{ticket['status'] != 'solved' && !end && bodyNew.data.ticket.files.length < 2 && (
								<div className={'size-12 bigButton secundary snow_background'}>
									<button className={'flex justify-between align-center'} onClick={openUpload.bind(this)}>
										<span>Sube tu archivo</span>
										<span>+</span>
									</button>
								</div>
							)}
							<div className={'size-12 bigButton snow_background ' + classDisabled + ' ' + classes.contentButton}>
								<button disabled={disabled} onClick={sendComment.bind(this)}>
									Enviar
								</button>
							</div>
						</div>
					)}
					{!load && ticket['status'] == 'solved' && !ticket.hasOwnProperty('ticket_rate') && showOptions && !end && (
						<div className={'size-12 snow_background'}>
							<div className={'size-12 itemsCloud'}>
								<div className={'itemsCloudTitle bold'}>¿Qué estuvo mal?</div>
								<div className={'itemsCloudList'}>
									<ul>
										{claves.map((item, key) => {
											//if(!nube[item].disabled){
											return (
												<li
													key={key}
													className={nube[item].active ? 'active' : nube[item].disabled ? 'disable' : ''}
													onClick={selectItem.bind(this, item)}
												>
													{nube[item].label}
												</li>
											);
											/*}else{
                                                return <li key={key} className={nube[item].active ? 'active' : 'disable'}>{nube[item].label}</li>
                                            }*/
										})}
									</ul>
								</div>
							</div>
						</div>
					)}
					{!load && ticket['status'] == 'solved' && !ticket.hasOwnProperty('ticket_rate') && !end && (
						<div className={'size-12 bigButton ' + (disabledFeed ? 'disable' : '') + ' ' + classes.buttonSend}>
							<button disabled={disabledFeed} onClick={sendFeed.bind(this)}>
								Enviar
							</button>
						</div>
					)}
					{!load && end && (
						<div className={classes.content + ' size-12 snow_background'}>
							<b>Gracias por tus sugerencias</b>
						</div>
					)}
					{!load && envio && (
						<p className={classes.content + ' size-12 snow_background'}>
							<b>¡Gracias!</b>
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default Ticket;
