/* eslint eqeqeq: "off", curly: "error", @typescript-eslint/no-unused-vars: "off", react-hooks/exhaustive-deps: "off", array-callback-return: "off", no-eval: "off", jsx-a11y/alt-text: "off", jsx-a11y/anchor-is-valid: "off" */
import React, { useState, useEffect } from 'react';
import * as Material from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
	title: {
		color: '#3743AA',
		margin: 'auto',
		textAlign: 'center',
		fontSize: '14px'
	},
	image: {
		width: '80%',
		marginLeft: '10%',
		marginRight: '10%'
	},
	text: {
		textAlign: 'center'
	}
});

const Final = props => {
	useEffect(() => {
		if (props.finalPage == 'errorConnect') {
			ReactGA.event({
				category: 'Error',
				action: `Error`,
				label: `Connect`
			});
			ReactGA.pageview('/error');
		} else {
			ReactGA.pageview('/final');
		}
	}, []);
	const classes = useStyles();
	const goHome = () => {
		window.location.reload();
	};
	return (
		<div>
			{props.finalPage == 'errorConnect' && (
				<div>
					<h1 className={classes.title}>Hay un problema al enviar</h1>
					<p className={'size-12 ' + classes.text}>Vuelve a intentar en unos minutos.</p>
				</div>
			)}
			{props.finalPage == 'success' && (
				<div>
					<h1 className={classes.title}>Tendrás una respuesta en menos de 15 minutos</h1>
					<br />
					<br />
					<br />
					<p className={classes.text}>Gracias por contactarnos, ¡tú eres lo más importante para nosotros!</p>
				</div>
			)}
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<div className={'size-12 bigButton'}>
				<button onClick={goHome.bind(this)}>Aceptar</button>
			</div>
		</div>
	);
};

export default Final;
