import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import App from './App';
import SearchPage from './pages/search/SearchPage';
import { AnimatePresence, motion } from 'framer-motion';
import ArticlePage from './pages/article/ArticlePage';
import TicketPage from './pages/ticket/TicketPage';
import { useStore } from 'react-redux';
import TicketSuccessPage from './pages/success/TicketSuccessPage';
import TicketErrorPage from './pages/error/TicketErrorPage';

const AppRoutes = () => {
	const store = useStore();
	store.subscribe(() => {
		sessionStorage.setItem('store', JSON.stringify(store.getState()));
	});
	//TODO: mover la animacion
	return (
		<Router>
			<Route
				render={({ location }) => (
					<AnimatePresence initial={false}>
						<Switch location={location} key={location.pathname}>
							<Route exact path="/">
								<motion.div
									exit="exit"
									variants={{
										exit: {
											transition: { duration: 2 },
											position: 'absolute',
											top: 0
										}
									}}
								>
									<App />
								</motion.div>
							</Route>
							<Route path="/search">
								<SearchPage />
							</Route>
							<Route path="/article/:id">
								<ArticlePage />
							</Route>
							<Route path="/ticket">
								<TicketPage />
							</Route>
							<Route path="/ticket-success">
								<TicketSuccessPage />
							</Route>
							<Route path="/ticket-error">
								<TicketErrorPage />
							</Route>
						</Switch>
					</AnimatePresence>
				)}
			/>
		</Router>
	);
};

export default AppRoutes;
