import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HeaderPage } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { selectArticle, setArticle } from '../../store/articleSlice';
import ReactHtmlParser from 'react-html-parser';
import * as HTTP from '../../http/http';
import { selectUser } from '../../store/userSlice';

const ArticlePage = () => {
	const { id } = useParams<{ id: string }>();
	const article = useSelector(selectArticle);
	const customer = useSelector(selectUser);
	const dispatch = useDispatch();
	const history = useHistory();
	const getArticle = async () => {
		try {
			let art = await HTTP.getArticle(id, customer);
			if (art.status === 200) {
				dispatch(setArticle(art.data.data));
			}
		} catch (e) {
			console.error(e);
		}
	};
	useEffect(() => {
		if (!article.uuid && id) {
			getArticle();
		} else if (!id && !article.uuid) {
			console.error('No se encontro el articulo');
		}
	});

	return (
		<div>
			<HeaderPage showLogo={false} goBack={true} />
			<h2>{article.name}</h2>
			<div>{ReactHtmlParser(article['article'])}</div>
			<div className={'size-12 bigButton snow_background'}>
				<button onClick={() => history.push('/ticket')}>Necesito más ayuda</button>
			</div>
		</div>
	);
};

export default ArticlePage;
