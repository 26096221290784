import React from 'react';
import * as Icons from '@material-ui/icons';
import styles from './thumbnails.module.css';

const Thumbnails = ({ files, removeFile }) => {
	return files.map((item, index) => {
		return (
			<div key={index + item} className={styles.container}>
				<img src={item} alt="archivos" className={styles.thumbnail} />
				<div>
					<span>Archivo {index + 1}</span>
					<Icons.DeleteForeverRounded onClick={() => removeFile(index)} />
				</div>
			</div>
		);
	});
};

export default Thumbnails;
